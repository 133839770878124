import React from "react"
import Layout from "../components/App/Layout"
import Navbar from "../components/App/Navbar"
import HostingPlanBlock from "../components/HostingPlans/HostingPlanBlock"
import InfoBlocks from "../components/Index/InfoBlocks"
import ClientFeedback from "../components/Index/ClientFeedback"
import Footer from "../components/App/Footer"
import BannerArea from "../components/Common/BannerArea"
import SEO from "../components/App/SEO"
import IntroHostingPlansParams from "../components/HostingPlans/IntroHostingPlansParams"
import PromoInfoRowParams from "../components/PromoInfoRow/PromoInfoRowParams"
import SiteBenefitsParams from "../components/SiteBenefits/SiteBenefitsParams"
import CustomerSupportParams from "../components/Index/CustomerSupportParams"
import FreeHosting from "../components/Common/FreeHosting";
import IntroHostingBgWhite from "../components/HostingPlans/IntroHostingBgWhite";


const GazduireMagentoSsd = () => {
    return (
        <Layout>
            <SEO title="Găzduire Magento - Sitebunker.ro - Pentru magazine online performante"
                 description="Ești în căutare de găzduire Magento pentru magazinul tău online? Descoperă pachetele Sitebunker.ro cu securitate sporită inclusă și resurse premium."
            />
            <Navbar/>

            <IntroHostingBgWhite
                title={'Găzduire Web Magento cu Eficiență Ridicată'}
                subtitle={'Descoperă resursele pachetelor de găzduire pentru Magento și alege-l pe cel potrivit magazinului tău online.'}
            />

            <HostingPlanBlock category="Magento"
                              showPromos={true}/>

            <BannerArea
                pageTitle="Găzduire Magento performantă"
                pageSubtitle="LiteMage Cache Server garantat"
                pageSubtitleSecond="Hosting eficient pentru magazine online de succes"
                pageDescription="Te bucuri de performanță şi vânzări online sporite cu pachetele de găzduire Magento oferite de SiteBunker. Tu focusează-te pe comenzi, de viteza de încărcare rapidă şi securitate ne ocupăm noi."
                svgIllustration="magento"
            />
            <FreeHosting title='Obține un hosting performant și primești un domeniu gratuit'
                         text1='Optează pentru un abonament de 1 an și profită de un hosting de calitate superioară de la Sitebunker. Ai parte de o găzduire eficientă pentru un magazin de succes Magento.'
                         text2='Oferta este activă pentru toate pachetele de hosting, cu excepția pachetului Start.'/>
            <PromoInfoRowParams
                title1="Performanță şi securitate"
                title2="Servicii găzduire Magento personalizate "
                title3="Migrare gratuită fără downtime"
                paragraph1="Soluție cache specializată asigurată prin LiteMage de la LiteSpeed, viteză de încărcare performantă, protecție avansată anti-hacking Imunify360, securitate garantată împotriva atacurilor DDoS prin noua soluție Arbor implementată şi 99.99% UPTIME."
                paragraph2="Indiferent de resursele de care ai nevoie, intră în legătură cu echipa suport SiteBunker pentru orice tip de abonamente de găzduire Magento personalizate. Oferim inclusiv Găzduire VPS despre care poți afla"
                buttonHere="detalii aici."
                paragraph3="Ai la dispoziția ta profesioniști gata să efectueze migrarea gratuit, fără downtime, atunci când ai luat decizia să te muți la SiteBunker. Ai 1 lună gratuită să te convingi de performanţele pachetelor de găzduire Magento."
            />
            <InfoBlocks/>
            <ClientFeedback
                title={"Află ce spun clienții despre serviciile de "}
                bold={"găzduire SiteBunker!"}/>
            <SiteBenefitsParams
                mainTitle="HOSTING MAGENTO CU RESURSE PREMIUM INCLUSE"
                title1={"CERTIFICAT SSL GRATUIT"}
                title2={"BACKUP ZILNIC AUTOMAT"}
                title3={"SUPORT 24/7"}
                text1={"Toate pachetele de găzduire Magento oferite de SiteBunker au incluse certificat SSL gratuit şi protecție avansată anti-hacking Imunify360."}
                text2={"Beneficiezi automat de backup zilnic GRATUIT într-o locație externă, astfel încât datele tale să fie întotdeauna în siguranță."}
                text3={"Ai asistență tehnică asigurată 24/7. Echipa suport este pregătită să te asiste prompt în orice situație tehnică tehnică te afli."}
            />
            <CustomerSupportParams
                title="Soluții personalizate web hosting."
                paragraph1="Procesezi un număr mare de acțiuni pe secundă?"
                paragraph2="SiteBunker îți pune la dispoziție găzduire VPS propulsată de procesoare AMD EPYC cu stocare full-SSD NVMe in RAID 10."
                buttonText="Cere detalii aici"
            />
            <Footer/>
        </Layout>
    )
}

export default GazduireMagentoSsd
